<template>
    <div class="pad-3">
      <div class="bb-container bb-container--lista">
        <h1 class="header-1">Eliminación de cuenta</h1>
        <div class="post-entry">
  
          <h3>Dónde hacer tu petición de eliminació de cuenta</h3>
  
          <p>
            Si deseas comunicarnos tu petición de eliminación de cuenta, puedes hacerlo escribiéndonos un correo electrónico a <a rel="nofollow" href="mailto:hola@hellobb.net">hola@hellobb.net</a>.
          </p>
  
          <h3>Desde la aplicación</h3>
  
          <p>
            Si lo prefieres, puedes eliminar tu cuenta directamente desde la aplicación móbil. Encontrarás la opción en el menú de configuración, que está accesible desde la opción "Más" situada en el rincón inferior derecho de la pantalla. Dentro de la configuración, encontrarás un "Eliminar mi cuenta" que te permitirá eliminar tu cuenta de HelloBB.
          </p>
  
          <h3>¿Qué datos eliminamos y cuáles conservamos al eliminar tu cuenta?</h3>
  
          <p>
            Cuando eliminamos tu cuenta, eliminamos todos los datos que nos has proporcionado, incluyendo tu nombre, email, contraseña. Por otra parte, conservamos datos relacionados con tu lista de nacimiento con fines estadísticos, pero no se pueden asociar a tu persona.
            <br />
          </p>
          
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "EliminacionCuenta",
    metaInfo() {
      return {
        title: "Eliminación de cuenta de HelloBB",
        meta: [
          { name: "description", content: "Cómo eliminar tu cuenta de HelloBB"},
          { name: "robots", content: "noindex"}
        ]
      };
    },
  
    data() {
      return {};
    },
    computed: {},
  
    // Server-side only
    // This will be called by the server renderer automatically
    serverPrefetch() {
      // return the Promise from the action
      // so that the component waits before rendering
    },
  
    // Client-side only
    mounted() {},
  
    methods: {},
  };
  </script>
  
  <style scoped>
  h3 {
    font-weight: bold;
  }
  </style>